@import url("https://fonts.googleapis.com/css?family=Open+Sans");
.alert {
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.75rem 1.25rem;
  position: relative; }

.alert-heading {
  color: inherit; }

.alert-cancel {
  background-color: #f8f9fa;
  border-color: #f6f7f8;
  color: #737678; }
  .alert-cancel hr {
    border-top-color: #e8eaed; }
  .alert-cancel .alert-link {
    color: #5a5c5e; }

.alert-danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-dark {
  background-color: #d6d8d9;
  border-color: #c6c8ca;
  color: #1b1e21; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.alert-info {
  background-color: #d1ecf1;
  border-color: #bee5eb;
  color: #0c5460; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-light {
  background-color: #fefefe;
  border-color: #fdfdfe;
  color: #818182; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-primary {
  background-color: #fce6d6;
  border-color: #fbdcc6;
  color: #7c441b; }
  .alert-primary hr {
    border-top-color: #f9cdae; }
  .alert-primary .alert-link {
    color: #522d12; }

.alert-secondary {
  background-color: #fbf8f2;
  border-color: #f9f6ed;
  color: #7a7362; }
  .alert-secondary hr {
    border-top-color: #f3ecda; }
  .alert-secondary .alert-link {
    color: #5e584b; }

.alert-success {
  background-color: #e9efd3;
  border-color: #e0e8c2;
  color: #4b5a13; }
  .alert-success hr {
    border-top-color: #d7e1af; }
  .alert-success .alert-link {
    color: #28300a; }

.alert-warning {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-white {
  background-color: white;
  border-color: white;
  color: #858585; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #6c6c6c; }

.badge {
  border-radius: 0.25rem;
  display: inline-block;
  font-size: 75%;
  line-height: 1;
  padding: 0.25rem 0.375rem;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap; }
  a.badge:hover {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -0.0625rem; }

.badge-pill {
  border-radius: 0.625rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem; }

.badge-cancel {
  background-color: #DEE2E6;
  color: #212529; }
  a.badge-cancel:hover {
    background-color: #c1c9d0;
    color: #212529; }
  a.badge-cancel:focus, a.badge-cancel.focus {
    box-shadow: 0 0 0 0.1875rem rgba(222, 226, 230, 0.5);
    outline: 0; }

.badge-danger {
  background-color: #DC3545;
  color: #FFF; }
  a.badge-danger:hover {
    background-color: #bd2130;
    color: #FFF; }
  a.badge-danger:focus, a.badge-danger.focus {
    box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.5);
    outline: 0; }

.badge-dark {
  background-color: #343A40;
  color: #FFF; }
  a.badge-dark:hover {
    background-color: #1d2124;
    color: #FFF; }
  a.badge-dark:focus, a.badge-dark.focus {
    box-shadow: 0 0 0 0.1875rem rgba(52, 58, 64, 0.5);
    outline: 0; }

.badge-info {
  background-color: #17A2B8;
  color: #FFF; }
  a.badge-info:hover {
    background-color: #117a8b;
    color: #FFF; }
  a.badge-info:focus, a.badge-info.focus {
    box-shadow: 0 0 0 0.1875rem rgba(23, 162, 184, 0.5);
    outline: 0; }

.badge-light {
  background-color: #F8F9FA;
  color: #212529; }
  a.badge-light:hover {
    background-color: #dae0e5;
    color: #212529; }
  a.badge-light:focus, a.badge-light.focus {
    box-shadow: 0 0 0 0.1875rem rgba(248, 249, 250, 0.5);
    outline: 0; }

.badge-primary {
  background-color: #EF8333;
  color: #212529; }
  a.badge-primary:hover {
    background-color: #de6811;
    color: #212529; }
  a.badge-primary:focus, a.badge-primary.focus {
    box-shadow: 0 0 0 0.1875rem rgba(239, 131, 51, 0.5);
    outline: 0; }

.badge-secondary {
  background-color: #EADEBD;
  color: #212529; }
  a.badge-secondary:hover {
    background-color: #decb96;
    color: #212529; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
    outline: 0; }

.badge-success {
  background-color: #90AD25;
  color: #FFF; }
  a.badge-success:hover {
    background-color: #6d831c;
    color: #FFF; }
  a.badge-success:focus, a.badge-success.focus {
    box-shadow: 0 0 0 0.1875rem rgba(144, 173, 37, 0.5);
    outline: 0; }

.badge-warning {
  background-color: #FFC107;
  color: #212529; }
  a.badge-warning:hover {
    background-color: #d39e00;
    color: #212529; }
  a.badge-warning:focus, a.badge-warning.focus {
    box-shadow: 0 0 0 0.1875rem rgba(255, 193, 7, 0.5);
    outline: 0; }

.badge-white {
  background-color: #FFF;
  color: #212529; }
  a.badge-white:hover {
    background-color: #e6e6e6;
    color: #212529; }
  a.badge-white:focus, a.badge-white.focus {
    box-shadow: 0 0 0 0.1875rem rgba(255, 255, 255, 0.5);
    outline: 0; }

.btn {
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  color: #212529;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0.625rem;
  text-align: center;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  .btn.disabled, .btn:disabled {
    box-shadow: none;
    opacity: 0.65;
    pointer-events: none; }
  .btn:focus, .btn.focus {
    outline: 0; }
  .btn:hover {
    color: #212529;
    text-decoration: none; }

.btn-sm {
  font-size: 0.875rem;
  height: 2rem;
  line-height: 1.875rem;
  padding: 0 0.625rem; }

.btn-cancel {
  background-color: #DEE2E6;
  border-color: #DEE2E6;
  color: #212529;
  fill: #212529; }
  .btn-cancel:focus, .btn-cancel.focus {
    background-color: #cfd5db;
    border-color: #c1c9d0;
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
    color: #212529; }
  .btn-cancel:hover {
    background-color: #cfd5db;
    border-color: #c1c9d0;
    color: #212529; }
  .btn-cancel.disabled, .btn-cancel:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-cancel.disabled:focus, .btn-cancel.disabled.focus, .btn-cancel:disabled:focus, .btn-cancel:disabled.focus {
      background-color: #CED4DA;
      border-color: #c1c9d0;
      box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
      color: #212529;
      fill: #212529; }

.btn-danger {
  background-color: #DC3545;
  border-color: #DC3545;
  color: #FFF;
  fill: #FFF; }
  .btn-danger:focus, .btn-danger.focus {
    background-color: #d32535;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
    color: #FFF; }
  .btn-danger:hover {
    background-color: #d32535;
    border-color: #bd2130;
    color: #FFF; }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger:disabled:focus, .btn-danger:disabled.focus {
      background-color: #CED4DA;
      border-color: #bd2130;
      box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
      color: #212529;
      fill: #212529; }

.btn-dark {
  background-color: #343A40;
  border-color: #343A40;
  color: #FFF;
  fill: #FFF; }
  .btn-dark:focus, .btn-dark.focus {
    background-color: #292d32;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
    color: #FFF; }
  .btn-dark:hover {
    background-color: #292d32;
    border-color: #1d2124;
    color: #FFF; }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-dark.disabled:focus, .btn-dark.disabled.focus, .btn-dark:disabled:focus, .btn-dark:disabled.focus {
      background-color: #CED4DA;
      border-color: #1d2124;
      box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
      color: #212529;
      fill: #212529; }

.btn-info {
  background-color: #17A2B8;
  border-color: #17A2B8;
  color: #FFF;
  fill: #FFF; }
  .btn-info:focus, .btn-info.focus {
    background-color: #148ea1;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
    color: #FFF; }
  .btn-info:hover {
    background-color: #148ea1;
    border-color: #117a8b;
    color: #FFF; }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info:disabled:focus, .btn-info:disabled.focus {
      background-color: #CED4DA;
      border-color: #117a8b;
      box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
      color: #212529;
      fill: #212529; }

.btn-light {
  background-color: #F8F9FA;
  border-color: #F8F9FA;
  color: #212529;
  fill: #212529; }
  .btn-light:focus, .btn-light.focus {
    background-color: #e9ecef;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
    color: #212529; }
  .btn-light:hover {
    background-color: #e9ecef;
    border-color: #dae0e5;
    color: #212529; }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-light.disabled:focus, .btn-light.disabled.focus, .btn-light:disabled:focus, .btn-light:disabled.focus {
      background-color: #CED4DA;
      border-color: #dae0e5;
      box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
      color: #212529;
      fill: #212529; }

.btn-primary {
  background-color: #EF8333;
  border-color: #EF8333;
  color: #212529;
  fill: #212529; }
  .btn-primary:focus, .btn-primary.focus {
    background-color: #ed751b;
    border-color: #de6811;
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
    color: #FFF; }
  .btn-primary:hover {
    background-color: #ed751b;
    border-color: #de6811;
    color: #FFF; }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary:disabled:focus, .btn-primary:disabled.focus {
      background-color: #CED4DA;
      border-color: #de6811;
      box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
      color: #212529;
      fill: #212529; }

.btn-secondary {
  background-color: #EADEBD;
  border-color: #EADEBD;
  color: #212529;
  fill: #212529; }
  .btn-secondary:focus, .btn-secondary.focus {
    background-color: #e4d4aa;
    border-color: #decb96;
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
    color: #212529; }
  .btn-secondary:hover {
    background-color: #e4d4aa;
    border-color: #decb96;
    color: #212529; }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-secondary.disabled:focus, .btn-secondary.disabled.focus, .btn-secondary:disabled:focus, .btn-secondary:disabled.focus {
      background-color: #CED4DA;
      border-color: #decb96;
      box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
      color: #212529;
      fill: #212529; }

.btn-success {
  background-color: #90AD25;
  border-color: #90AD25;
  color: #FFF;
  fill: #FFF; }
  .btn-success:focus, .btn-success.focus {
    background-color: #7f9821;
    border-color: #6d831c;
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
    color: #FFF; }
  .btn-success:hover {
    background-color: #7f9821;
    border-color: #6d831c;
    color: #FFF; }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success:disabled:focus, .btn-success:disabled.focus {
      background-color: #CED4DA;
      border-color: #6d831c;
      box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
      color: #212529;
      fill: #212529; }

.btn-warning {
  background-color: #FFC107;
  border-color: #FFC107;
  color: #212529;
  fill: #212529; }
  .btn-warning:focus, .btn-warning.focus {
    background-color: #edb100;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
    color: #212529; }
  .btn-warning:hover {
    background-color: #edb100;
    border-color: #d39e00;
    color: #212529; }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning:disabled:focus, .btn-warning:disabled.focus {
      background-color: #CED4DA;
      border-color: #d39e00;
      box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
      color: #212529;
      fill: #212529; }

.btn-white {
  background-color: #FFF;
  border-color: #FFF;
  color: #212529;
  fill: #212529; }
  .btn-white:focus, .btn-white.focus {
    background-color: #f2f2f2;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
    color: #212529; }
  .btn-white:hover {
    background-color: #f2f2f2;
    border-color: #e6e6e6;
    color: #212529; }
  .btn-white.disabled, .btn-white:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-white.disabled:focus, .btn-white.disabled.focus, .btn-white:disabled:focus, .btn-white:disabled.focus {
      background-color: #CED4DA;
      border-color: #e6e6e6;
      box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
      color: #212529;
      fill: #212529; }

@keyframes btn-loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.btn-loader {
  color: transparent !important;
  cursor: default;
  fill: transparent !important;
  pointer-events: none;
  position: relative; }
  .btn-loader:after {
    animation: btn-loader 300ms linear infinite;
    border: 0.125rem solid rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    border-top-color: #FFF;
    content: "";
    display: block;
    height: 1.5rem;
    left: 50%;
    margin-left: -0.75rem;
    margin-top: -0.75rem;
    position: absolute;
    top: 50%;
    transition: opacity;
    width: 1.5rem; }

.card {
  background-clip: border-box;
  background-color: #FFF;
  border: 0.0625rem solid #ADB5BD;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  word-wrap: break-word; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-radius: 0.25rem 0.25rem 0 0; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-radius: 0 0 0.25rem 0.25rem; }

.card-header {
  background-color: #E9ECEF;
  border-bottom: 0.0625rem solid #ADB5BD;
  border-radius: 0.25rem 0.25rem 0 0;
  margin-bottom: 0;
  padding: 1rem 1.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle:after {
    content: "";
    display: inline-block;
    margin-left: 0.25rem;
    vertical-align: 0.25rem;
    border-bottom: 0;
    border-left: 0.0625rem solid transparent;
    border-right: 0.0625rem solid transparent;
    border-top: 0.0625rem solid; }
  .dropdown-toggle:empty:after {
    margin-left: 0; }

.dropdown-menu {
  background-color: #FFF;
  border: 0.0625rem solid #CED4DA;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  color: #212529;
  display: none;
  float: left;
  left: 0;
  list-style: none;
  margin: 0.125rem 0 0;
  min-width: 10rem;
  padding: 0.5rem 0;
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: 1000; }

.dropdown-menu-left {
  left: 0;
  right: auto; }

.dropdown-menu-right {
  left: auto;
  right: 0; }

@media (min-width: 30rem) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto; }
  .dropdown-menu-sm-right {
    left: auto;
    right: 0; } }

@media (min-width: 48rem) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto; }
  .dropdown-menu-md-right {
    left: auto;
    right: 0; } }

@media (min-width: 62rem) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto; }
  .dropdown-menu-lg-right {
    left: auto;
    right: 0; } }

@media (min-width: 75rem) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto; }
  .dropdown-menu-xl-right {
    left: auto;
    right: 0; } }

.dropup .dropdown-menu {
  bottom: 100%;
  margin-bottom: 0.125rem;
  margin-top: 0;
  top: auto; }

.dropup .dropdown-toggle:after {
  content: "";
  display: inline-block;
  margin-left: 0.25rem;
  vertical-align: 0.25rem;
  border-bottom: 0.0625rem solid;
  border-left: 0.0625rem solid transparent;
  border-right: 0.0625rem solid transparent;
  border-top: 0; }

.dropup .dropdown-toggle:empty:after {
  margin-left: 0; }

.dropright .dropdown-menu {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: 0;
  right: auto;
  top: 0; }

.dropright .dropdown-toggle:after {
  content: "";
  display: inline-block;
  margin-left: 0.25rem;
  vertical-align: 0.25rem;
  border-bottom: 0.0625rem solid transparent;
  border-left: 0.0625rem solid;
  border-right: 0;
  border-top: 0.0625rem solid transparent; }

.dropright .dropdown-toggle:empty:after {
  margin-left: 0; }

.dropright .dropdown-toggle:after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  left: auto;
  margin-right: 0.125rem;
  margin-top: 0;
  right: 100%;
  top: 0; }

.dropleft .dropdown-toggle:after {
  content: "";
  display: inline-block;
  margin-left: 0.25rem;
  vertical-align: 0.25rem; }

.dropleft .dropdown-toggle:after {
  display: none; }

.dropleft .dropdown-toggle:before {
  content: "";
  display: inline-block;
  margin-right: 0.25rem;
  vertical-align: 0.25rem;
  border-bottom: 0.0625rem solid transparent;
  border-right: 0.0625rem solid;
  border-top: 0.0625rem solid transparent; }

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0; }

.dropleft .dropdown-toggle:before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  bottom: auto;
  right: auto; }

.dropdown-divider {
  border-top: 0.0625rem solid #E9ECEF;
  height: 0;
  margin: 0.25rem 0;
  overflow: hidden; }

.dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: #212529;
  display: block;
  padding: 0.25rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%; }
  .dropdown-item:focus, .dropdown-item:hover {
    background-color: #E9ECEF;
    color: #16181b;
    text-decoration: none; }
  .dropdown-item.active, .dropdown-item:active {
    background-color: #EF8333;
    color: #FFF;
    text-decoration: none; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    background-color: transparent;
    color: #868E96;
    pointer-events: none; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  color: #868E96;
  display: block;
  margin-bottom: 0;
  padding: 8 24;
  white-space: nowrap; }

.dropdown-item-text {
  color: #212529;
  display: block;
  padding: 4 24; }

.custom-file {
  display: inline-block;
  font-size: 1rem;
  height: calc(1.5em + 0.75rem + 0.125rem);
  margin-bottom: 0;
  position: relative;
  width: 100%; }

.custom-file-input {
  cursor: pointer;
  height: calc(1.5em + 0.75rem + 0.125rem);
  margin: 0;
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 2; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #EADEBD;
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #E9ECEF; }
  .custom-file-input:lang(de) ~ .custom-file-label:after {
    content: "Durchsuchen"; }
  .custom-file-input:lang(en) ~ .custom-file-label:after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]:after {
    content: attr(data-browse); }

.custom-file-label {
  background-color: #FFF;
  border: 0.0625rem solid #ADB5BD;
  border-radius: 0.25rem;
  color: #495057;
  height: calc(1.5em + 0.75rem + 0.125rem);
  left: 0;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1; }
  .custom-file-label:after {
    background: #E9ECEF;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
    bottom: 0;
    color: #495057;
    display: block;
    height: calc(1.5em + 12);
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3; }

#image_view div {
  display: inline-block;
  padding: 1rem; }

label {
  display: inline-block;
  line-height: 1.5; }

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0; }

legend {
  color: inherit;
  display: block;
  font-size: 1.125rem;
  line-height: inherit;
  margin-bottom: 0.5rem;
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%; }

input {
  border-radius: 0;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: visible; }
  input:disabled {
    cursor: not-allowed; }
  input[type="search"] {
    appearance: none;
    outline-offset: -0.125rem; }
    input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none; }
  input[type="date"], input[type="datetime-local"], input[type="time"], input[type="month"] {
    appearance: listbox;
    cursor: text;
    min-width: 100%; }
  input::-webkit-contacts-auto-fill-button, input::-webkit-credentials-auto-fill-button {
    pointer-events: none;
    visibility: hidden; }
  input::-webkit-clear-button, input::-ms-clear {
    display: none; }
  input::-ms-expand {
    background-color: transparent;
    border: 0; }

select {
  border-radius: 0;
  cursor: pointer;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
  text-transform: none;
  word-wrap: normal; }
  select::-ms-expand {
    display: none; }
  select:focus::-ms-value {
    background: transparent; }
  select[disabled] {
    cursor: not-allowed; }

optgroup {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0; }

textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: auto;
  resize: vertical; }
  textarea[disabled] {
    cursor: not-allowed; }
  textarea.form-control {
    height: auto; }

button {
  border-radius: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: hidden;
  text-transform: none;
  user-select: none; }
  button:focus {
    outline: 0.0625rem dotted; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button,
[type="reset"],
[type="submit"] {
  appearance: button; }

.form-group {
  margin-bottom: 1rem; }

.form-control, .custom-select {
  background-color: #FFF;
  border: 0.0625rem solid #ADB5BD;
  border-radius: 0.25rem;
  color: #495057;
  font-size: 1rem;
  height: 2.4375rem;
  transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
  width: 100%; }
  .form-control:focus, .custom-select:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }

.custom-date,
.custom-time,
.custom-number, .custom-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343A40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 0.5rem 0.625rem; }

.form-control {
  background-clip: padding-box;
  display: block;
  padding: 0.375rem 0.625rem; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #E9ECEF;
    color: #868E96;
    opacity: 1; }
  .form-control::placeholder {
    color: #868E96;
    opacity: 1; }

.form-control-sm {
  font-size: 0.875rem;
  height: 2rem;
  padding: 0.125rem 0.375rem; }

.custom-date::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 0; }

.custom-time::-webkit-inner-spin-button, .custom-time::-webkit-outer-spin-button,
.custom-number::-webkit-inner-spin-button,
.custom-number::-webkit-outer-spin-button {
  cursor: pointer;
  opacity: 0; }

.custom-control {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  position: relative; }

.custom-control-label {
  margin-bottom: 0;
  position: relative;
  vertical-align: top; }
  .custom-control-label:before {
    background-color: #FFF;
    border: 0.0625rem solid #ADB5BD;
    content: "";
    display: block;
    height: 1rem;
    left: -1.5rem;
    pointer-events: none;
    position: absolute;
    top: 0.25rem;
    transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
    width: 1rem; }
  .custom-control-label:after {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 50%;
    content: "";
    display: block;
    height: 1rem;
    left: -1.5rem;
    position: absolute;
    top: 0.25rem;
    width: 1rem; }

.custom-control-input {
  opacity: 0;
  position: absolute;
  z-index: -1; }
  .custom-control-input:checked ~ .custom-control-label:before {
    background: #EADEBD;
    border-color: #EADEBD; }
  .custom-control-input:focus ~ .custom-control-label:before {
    border-color: #EADEBD;
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5); }
  .custom-control-input[disabled] ~ .custom-control-label {
    color: #868E96; }
    .custom-control-input[disabled] ~ .custom-control-label:before {
      background-color: #E9ECEF; }

.custom-checkbox .custom-control-label:before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-label:before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e"); }

.custom-select {
  appearance: none;
  display: inline-block;
  height: 2rem;
  line-height: 1.25rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  vertical-align: middle; }
  .custom-select:focus::-ms-value {
    background-color: #FFF;
    color: #495057; }
  .custom-select:disabled {
    background-color: #E9ECEF;
    color: #868E96; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    background-image: none;
    height: auto;
    padding-right: 0.75rem; }

.custom-select-sm {
  background-position: right 0.5rem center;
  font-size: 0.875rem;
  height: 2rem;
  line-height: 1.625rem;
  padding: 0.125rem 1.375rem 0.125rem 0.375rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }
  .form-text ~ .invalid-feedback {
    margin: 0; }

.invalid-feedback {
  color: #DC3545;
  display: none;
  margin-top: 0.25rem;
  width: 100%; }

.form-control.is-invalid {
  border-color: #DC3545; }
  .form-control.is-invalid:focus {
    border-color: #DC3545;
    box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.25); }
  .form-control.is-invalid ~ .invalid-feedback {
    display: block; }

.form-group.is-invalid .select2-container.select2-container--bootstrap, .form-group.is-invalid .select2-selection {
  border-color: #DC3545; }
  .form-group.is-invalid .select2-container.select2-container--bootstrap:focus, .form-group.is-invalid .select2-selection:focus {
    border-color: #DC3545;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(220, 53, 69, 0.6); }
  .form-group.is-invalid .select2-container.select2-container--bootstrap ~ .invalid-feedback, .form-group.is-invalid .select2-selection ~ .invalid-feedback {
    display: block; }

.form-group.is-valid .select2-container.select2-container--bootstrap, .form-group.is-valid .select2-selection {
  border-color: #28a745; }
  .form-group.is-valid .select2-container.select2-container--bootstrap:focus, .form-group.is-valid .select2-selection:focus {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .form-group.is-valid .select2-container.select2-container--bootstrap ~ .invalid-feedback, .form-group.is-valid .select2-selection ~ .invalid-feedback {
    display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #DC3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label:before, .custom-control-input.is-invalid ~ .custom-control-label:before {
    border-color: #DC3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-feedback {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before, .custom-control-input.is-invalid:checked ~ .custom-control-label:before {
  border-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before, .custom-control-input.is-invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #DC3545; }

.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #DC3545; }

.custom-file-input.is-invalid ~ .invalid-feedback {
  display: block; }

.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #DC3545;
  box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.25); }

.error {
  border-color: #DC3545 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(220, 53, 69, 0.6) !important; }

label.error {
  border-color: transparent !important;
  box-shadow: none !important;
  color: #DC3545; }

.select2-selection.select2-selection--multiple {
  min-height: 2.3rem; }

.container-fluid {
  margin: 0 auto;
  min-width: 20rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  width: 100%; }
  @media (min-width: 30rem) {
    .container-fluid {
      padding-left: calc(0.9375rem + 2.8125 * (100vw - 30rem) / (75 - 30));
      padding-right: calc(0.9375rem + 2.8125 * (100vw - 30rem) / (75 - 30)); } }
  @media (min-width: 75rem) {
    .container-fluid {
      padding-left: 3.75rem;
      padding-right: 3.75rem; } }

.container-max-fluid {
  margin: 0 auto;
  min-width: 20rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  width: 100%;
  max-width: 75rem; }
  @media (min-width: 30rem) {
    .container-max-fluid {
      padding-left: calc(0.9375rem + 2.8125 * (100vw - 30rem) / (75 - 30));
      padding-right: calc(0.9375rem + 2.8125 * (100vw - 30rem) / (75 - 30)); } }
  @media (min-width: 75rem) {
    .container-max-fluid {
      padding-left: 3.75rem;
      padding-right: 3.75rem; } }

.container {
  margin: 0 auto;
  min-width: 20rem;
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  width: 100%;
  max-width: 0; }
  @media (min-width: 30rem) {
    .container {
      padding-left: calc(0.9375rem + 2.8125 * (100vw - 30rem) / (75 - 30));
      padding-right: calc(0.9375rem + 2.8125 * (100vw - 30rem) / (75 - 30)); } }
  @media (min-width: 75rem) {
    .container {
      padding-left: 3.75rem;
      padding-right: 3.75rem; } }
  @media (min-width: 30rem) {
    .container {
      max-width: 30rem; } }
  @media (min-width: 48rem) {
    .container {
      max-width: 48rem; } }
  @media (min-width: 62rem) {
    .container {
      max-width: 62rem; } }
  @media (min-width: 75rem) {
    .container {
      max-width: 75rem; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.46875rem;
  margin-right: -0.46875rem; }
  @media (min-width: 30rem) {
    .row {
      margin-left: calc(-0.46875rem - 1.40625 * (100vw - 30rem) / (75 - 30));
      margin-right: calc(-0.46875rem - 1.40625 * (100vw - 30rem) / (75 - 30)); } }
  @media (min-width: 75rem) {
    .row {
      margin-left: -1.875rem;
      margin-right: -1.875rem; } }
  .row.sm-gutters {
    margin-left: -0.23438rem;
    margin-right: -0.23438rem; }
    @media (min-width: 30rem) {
      .row.sm-gutters {
        margin-left: calc(-0.23438rem - 0.70312 * (100vw - 30rem) / (75 - 30));
        margin-right: calc(-0.23438rem - 0.70312 * (100vw - 30rem) / (75 - 30)); } }
    @media (min-width: 75rem) {
      .row.sm-gutters {
        margin-left: -0.9375rem;
        margin-right: -0.9375rem; } }

.sm-gutters > .col,
.sm-gutters > [class*="col-"] {
  padding-left: 0.23438rem;
  padding-right: 0.23438rem; }
  @media (min-width: 30rem) {
    .sm-gutters > .col,
    .sm-gutters > [class*="col-"] {
      padding-left: calc(0.23438rem + 0.70312 * (100vw - 30rem) / (75 - 30));
      padding-right: calc(0.23438rem + 0.70312 * (100vw - 30rem) / (75 - 30)); } }
  @media (min-width: 75rem) {
    .sm-gutters > .col,
    .sm-gutters > [class*="col-"] {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem; } }

.no-gutters {
  margin-left: 0;
  margin-right: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  position: relative;
  width: 100%; }
  @media (min-width: 30rem) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      padding-left: calc(0.46875rem + 1.40625 * (100vw - 30rem) / (75 - 30));
      padding-right: calc(0.46875rem + 1.40625 * (100vw - 30rem) / (75 - 30)); } }
  @media (min-width: 75rem) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      padding-left: 1.875rem;
      padding-right: 1.875rem; } }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  max-width: 100%;
  width: auto; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 30rem) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 48rem) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 62rem) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 75rem) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

svg {
  overflow: hidden;
  vertical-align: middle; }

img {
  border-style: none;
  vertical-align: middle; }

.img-fluid {
  height: auto;
  max-width: 100%; }

.list-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0; }

.list-group-flush .list-group-item {
  border-left: 0;
  border-radius: 0;
  border-right: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -0.0625rem; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
  margin-bottom: 0; }

.list-group-item {
  background-color: #FFF;
  border: 0.0625rem solid #CED4DA;
  display: block;
  margin-bottom: -0.0625rem;
  padding: 0.5rem 1.25rem;
  position: relative; }
  .list-group-item:first-child {
    border-radius: 0.25rem 0.25rem 0 0; }
  .list-group-item:last-child {
    border-radius: 0 0 0.25rem 0.25rem;
    margin-bottom: 0; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #868E96;
    pointer-events: none; }
  .list-group-item.active {
    background-color: #EADEBD;
    border-color: #EADEBD;
    color: #212529;
    z-index: 2; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  display: none;
  height: 100%;
  margin: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8070; }

.modal-dialog {
  left: 25%;
  pointer-events: none;
  position: fixed;
  right: 25%;
  width: 50%; }
  .modal.fade .modal-dialog {
    transform: translate(0, -3.125rem);
    transition: transform 300ms ease-out; }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered:before {
    content: "";
    display: block;
    height: calc(100vh - 1rem); }

.modal-sm .modal-content {
  max-width: 18.75rem; }

.modal-lg .modal-content {
  max-width: 50rem; }

.modal-xl .modal-content {
  max-width: 71.25rem; }

.modal-content {
  background-clip: padding-box;
  background-color: #FFF;
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%; }

.modal-backdrop {
  background-color: #000;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 8060; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.4; }

.modal-header {
  align-items: flex-start;
  border-bottom: 0.0625rem solid #ADB5BD;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem; }
  .modal-header .close {
    margin: -1rem -1rem -1rem auto;
    padding: 1rem; }

.modal-title {
  line-height: 1.5;
  margin-bottom: 0; }

.modal-body {
  flex: 1 1 auto;
  padding: 1rem;
  position: relative; }

.modal-footer {
  align-items: center;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top: 0.0625rem solid #ADB5BD;
  display: flex;
  justify-content: flex-end;
  padding: 1rem; }
  .modal-footer > :not(:first-child) {
    margin-left: 0.25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: 0.25rem; }

.modal-scrollbar-measure {
  height: 3.125rem;
  overflow: scroll;
  position: absolute;
  top: -624.9375rem;
  width: 3.125rem; }

@media (min-width: 62rem) {
  .modal-lg {
    margin-bottom: 1.75rem;
    margin-top: 1.75rem; }
    .modal-lg.modal-dialog-centered {
      min-height: calc(100% - 3.5rem); }
      .modal-lg.modal-dialog-centered:before {
        height: calc(100vh - 3.5rem); } }

@media (min-width: 75rem) {
  .modal-xl {
    margin-bottom: 1.75rem;
    margin-top: 1.75rem; }
    .modal-xl.modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem); }
      .modal-xl.modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem); }
    .modal-xl.modal-dialog-centered {
      min-height: calc(100% - 3.5rem); }
      .modal-xl.modal-dialog-centered:before {
        height: calc(100vh - 3.5rem); } }

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0; }

.nav-link {
  display: block;
  padding: 0.375rem 0.625rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #868E96;
    cursor: default;
    pointer-events: none; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active {
  background-color: #EF8333;
  color: #FFF; }

.navbar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.5rem 0rem;
  position: relative; }
  .navbar > .container,
  .navbar > .container-fluid {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  line-height: inherit;
  margin-right: 0rem;
  white-space: nowrap; }
  .navbar-brand:focus, .navbar-brand:hover {
    outline: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0; }
  .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0; }
  .navbar-nav .dropdown-menu {
    float: none;
    position: static; }
  .navbar-nav .active {
    position: relative; }
    .navbar-nav .active:after {
      border-bottom: 0.3125rem solid #EADEBD;
      bottom: -0.5rem;
      content: "";
      left: 0;
      position: absolute;
      right: 0; }

.navbar-text {
  display: inline-block;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem; }

.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1; }

@media (max-width: xs) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 30rem) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: sm) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 48rem) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: md) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 62rem) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: lg) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 75rem) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-left: 0.5rem;
      padding-right: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
      color: rgba(0, 0, 0, 0.9); }

.navbar-light .icon {
  fill: rgba(0, 0, 0, 0.5); }

.navbar-dark .navbar-brand {
  color: #FFF; }
  .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: #FFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8); }
  .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFF; }

.navbar-dark .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.8); }
  .navbar-dark .navbar-text a {
    color: #FFF; }
    .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
      color: #FFF; }

.navbar-dark .icon {
  fill: rgba(255, 255, 255, 0.8); }

.page-link {
  background-color: #FFF;
  border: 0.0625rem solid #DDD;
  color: #212529;
  cursor: pointer;
  display: block;
  line-height: 1.25;
  margin-left: -0.0625rem;
  padding: 0.5rem 0.75rem;
  position: relative;
  user-select: none; }
  .page-link:hover {
    background-color: #E9ECEF;
    border-color: #DDD;
    color: #212529;
    text-decoration: none;
    z-index: 2; }
  .page-link:focus {
    border-color: #EADEBD;
    box-shadow: 0 0 0 0.1875rem rgba(234, 222, 189, 0.5);
    outline: 0;
    z-index: 3; }

.page-item.active .page-link {
  background-color: #EADEBD;
  border-color: #EADEBD;
  color: #212529;
  z-index: 3; }

.progress {
  background-color: #E9ECEF;
  border-radius: 0.25rem;
  display: flex;
  font-size: 0.75rem;
  height: 1rem;
  overflow: hidden; }

.progress-bar {
  background-color: #EADEBD;
  color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: width 600ms ease;
  white-space: nowrap; }

table {
  border-collapse: collapse;
  max-width: 100%;
  width: 100%; }

th,
td {
  text-align: inherit;
  vertical-align: top; }

thead th {
  vertical-align: bottom; }

.table {
  background-color: transparent;
  margin-bottom: 1rem; }
  .table th,
  .table td {
    border-top: 0.0625rem solid #ADB5BD;
    padding: 0.75rem; }
  .table thead th {
    border-bottom: 0.125rem solid #ADB5BD; }
  .table tfoot {
    display: table-header-group; }

.table-sm th,
.table-sm td {
  padding: 0.375rem; }

.table-striped tbody tr:nth-of-type(even) {
  background-color: #F8F9FA; }

.selected-project {
  background-color: #CED4DA !important; }

.table-hover tbody tr:hover {
  background-color: #CED4DA;
  color: #212529; }

.table-cancel,
.table-cancel > th,
.table-cancel > td {
  background-color: #f6f7f8;
  color: #212529; }

.table-cancel th,
.table-cancel td,
.table-cancel thead th,
.table-cancel tbody + tbody {
  border-color: #eef0f2; }

.table-hover .table-cancel:hover {
  background-color: #e8eaed; }
  .table-hover .table-cancel:hover > td,
  .table-hover .table-cancel:hover > th {
    background-color: #e8eaed; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
  color: #212529; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
  color: #212529; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
  color: #212529; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
  color: #212529; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fbdcc6;
  color: #212529; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f7bf95; }

.table-hover .table-primary:hover {
  background-color: #f9cdae; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #f9cdae; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f9f6ed;
  color: #212529; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f4eedd; }

.table-hover .table-secondary:hover {
  background-color: #f3ecda; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f3ecda; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e0e8c2;
  color: #212529; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #c5d48e; }

.table-hover .table-success:hover {
  background-color: #d7e1af; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d7e1af; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
  color: #212529; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
  color: #212529; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

@media (max-width: xs) {
  .table-responsive-sm {
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: sm) {
  .table-responsive-md {
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: md) {
  .table-responsive-lg {
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: lg) {
  .table-responsive-xl {
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  width: 100%; }
  .table-responsive > .table-bordered {
    border: 0; }

.toast-wrapper {
  bottom: 1rem;
  position: absolute;
  right: 0;
  right: 0.46875rem;
  z-index: 2000; }
  @media (min-width: 30rem) {
    .toast-wrapper {
      right: calc(0.9375rem + 2.8125 * (100vw - 30rem) / (75 - 30)); } }
  @media (min-width: 75rem) {
    .toast-wrapper {
      right: 3.75rem; } }

.toast {
  font-size: 0.875rem;
  backdrop-filter: blur(0.625rem);
  background-clip: padding-box;
  background-color: #FFF;
  border: 0.0625rem solid #CED4DA;
  border-radius: 4;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  max-width: 21.875rem;
  opacity: 0;
  overflow: hidden; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  align-items: center;
  background-clip: padding-box;
  background-color: #E9ECEF;
  border-bottom: 0.0625rem solid #CED4DA;
  color: #868E96;
  display: flex;
  padding: 0.25rem 0.75rem; }

.toast-body {
  padding: 0.75rem; }

.tooltip {
  font-style: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  display: block;
  font-size: 0.875rem;
  margin: 0.125rem;
  opacity: 0;
  position: absolute;
  word-wrap: break-word;
  z-index: 9000; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .tooltip-arrow {
    display: block;
    height: 0.5rem;
    position: absolute;
    width: 0.75rem; }
    .tooltip .tooltip-arrow:before {
      border-color: transparent;
      border-style: solid;
      content: "";
      position: absolute; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.5rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow:before {
      border-top-color: #343A40;
      border-width: 0.5rem 0.375rem 0;
      top: 0; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.5rem; }
  .bs-tooltip-right .tooltip-arrow, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow {
    height: 0.5rem;
    left: 0;
    width: 0.5rem; }
    .bs-tooltip-right .tooltip-arrow:before, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow:before {
      border-right-color: #343A40;
      border-width: 0.375rem 0.5rem 0.375rem 0;
      right: 0;
      top: -0.125rem; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.5rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow:before {
      border-bottom-color: #343A40;
      border-width: 0 0.375rem 0.5rem;
      bottom: 0; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.5rem; }
  .bs-tooltip-left .tooltip-arrow, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow {
    height: 0.5rem;
    right: 0;
    top: -0.125rem;
    width: 0.5rem; }
    .bs-tooltip-left .tooltip-arrow:before, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow:before {
      border-left-color: #343A40;
      border-width: 0.375rem 0 0.375rem 0.5rem;
      left: 0; }

.tooltip-inner {
  background-color: #343A40;
  border-radius: 0.25rem;
  color: #FFF;
  max-width: 12.5rem;
  padding: 0.1875rem 0.375rem;
  text-align: center; }

.fade {
  transition: opacity 150ms linear; }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height 350ms ease; }

body {
  font-size: 0.875rem;
  background-color: #FFF;
  color: #212529;
  font-feature-settings: "liga", "kern";
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  margin: 0;
  min-width: 20rem;
  overflow-y: scroll;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  text-rendering: geometricPrecision;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 30rem) {
    body {
      font-size: calc(0.875rem + 0.0625 * (100vw - 30rem) / (75 - 30)); } }
  @media (min-width: 75rem) {
    body {
      font-size: 0.9375rem; } }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #212529;
  line-height: 1.2;
  margin-bottom: 0.875rem; }
  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
  .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
    font-size: 75%; }

h1,
.h1 {
  font-size: 1.6875rem; }
  @media (min-width: 30rem) {
    h1,
    .h1 {
      font-size: calc(1.6875rem + 0.6875 * (100vw - 30rem) / (75 - 30)); } }
  @media (min-width: 75rem) {
    h1,
    .h1 {
      font-size: 2.375rem; } }

h2,
.h2 {
  font-size: 1.375rem; }
  @media (min-width: 30rem) {
    h2,
    .h2 {
      font-size: calc(1.375rem + 0.375 * (100vw - 30rem) / (75 - 30)); } }
  @media (min-width: 75rem) {
    h2,
    .h2 {
      font-size: 1.75rem; } }

h3,
.h3 {
  font-size: 1.125rem; }
  @media (min-width: 30rem) {
    h3,
    .h3 {
      font-size: calc(1.125rem + 0.1875 * (100vw - 30rem) / (75 - 30)); } }
  @media (min-width: 75rem) {
    h3,
    .h3 {
      font-size: 1.3125rem; } }

h4,
.h4 {
  font-size: 0.875rem;
  line-height: 1.5; }
  @media (min-width: 30rem) {
    h4,
    .h4 {
      font-size: calc(0.875rem + 0.125 * (100vw - 30rem) / (75 - 30)); } }
  @media (min-width: 75rem) {
    h4,
    .h4 {
      font-size: 1rem; } }

h5,
.h5 {
  font-size: 0.875rem;
  line-height: 1.5; }
  @media (min-width: 30rem) {
    h5,
    .h5 {
      font-size: calc(0.875rem + 0.125 * (100vw - 30rem) / (75 - 30)); } }
  @media (min-width: 75rem) {
    h5,
    .h5 {
      font-size: 1rem; } }

p {
  margin-bottom: 0.875rem;
  margin-top: 0; }

a {
  background-color: transparent;
  color: #EADEBD;
  text-decoration: none; }
  a:focus {
    outline: 0.125rem solid #EADEBD;
    outline-offset: 0.0625rem;
    position: relative;
    text-decoration: none;
    z-index: 3000; }
  a:hover {
    color: #EADEBD;
    text-decoration: underline; }
  a[data-focus-method="mouse"], a[data-focus-method="touch"] {
    outline: 0; }

hr {
  border: 0;
  border-top: 0.0625rem solid #CED4DA;
  box-sizing: content-box;
  height: 0;
  margin-bottom: 1rem;
  margin-top: 1rem;
  overflow: visible; }

small,
.small {
  font-size: 87.5%; }

abbr[title],
abbr[data-original-title] {
  border-bottom: 0;
  cursor: help;
  text-decoration: none; }

ol,
ul {
  margin-bottom: 1rem;
  margin-top: 0; }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0; }

dl {
  margin-bottom: 1rem;
  margin-top: 0; }

.list-styled {
  list-style: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3e%3crect fill='%23212529' height='7' width='7' x='0' y='1'/%3e%3c/svg%3e");
  margin-left: 0;
  padding-left: 1.0625rem; }

.list-unstyled {
  list-style: none;
  padding-left: 0; }

.links_list {
  list-style: none; }
  .links_list a {
    color: #07C; }
    .links_list a:hover {
      text-decoration: underline; }
    .links_list a:focus {
      outline: none; }

.link_form_container .link_form_item {
  border: 1px solid rgba(0, 0, 0, 0.22);
  border-radius: 5px; }

.link_form_container .delete_link:hover, .link_form_container .add_link_form i:hover {
  color: #EADEBD;
  cursor: pointer; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-cancel {
  background-color: #DEE2E6 !important; }

.bg-danger {
  background-color: #DC3545 !important; }

.bg-dark {
  background-color: #343A40 !important; }

.bg-info {
  background-color: #17A2B8 !important; }

.bg-light {
  background-color: #F8F9FA !important; }

.bg-primary {
  background-color: #EF8333 !important; }

.bg-secondary {
  background-color: #EADEBD !important; }

.bg-success {
  background-color: #90AD25 !important; }

.bg-warning {
  background-color: #FFC107 !important; }

.bg-white {
  background-color: #FFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 0.0625rem solid #ADB5BD !important; }

.border-top {
  border-top: 0.0625rem solid #ADB5BD !important; }

.border-right {
  border-right: 0.0625rem solid #ADB5BD !important; }

.border-bottom {
  border-bottom: 0.0625rem solid #ADB5BD !important; }

.border-left {
  border-left: 0.0625rem solid #ADB5BD !important; }

.border-cancel {
  border-color: #DEE2E6 !important; }

.border-danger {
  border-color: #DC3545 !important; }

.border-dark {
  border-color: #343A40 !important; }

.border-info {
  border-color: #17A2B8 !important; }

.border-light {
  border-color: #F8F9FA !important; }

.border-primary {
  border-color: #EF8333 !important; }

.border-secondary {
  border-color: #EADEBD !important; }

.border-success {
  border-color: #90AD25 !important; }

.border-warning {
  border-color: #FFC107 !important; }

.border-white {
  border-color: #FFF !important; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 30rem) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 48rem) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 62rem) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 75rem) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%; }
  .embed-responsive:before {
    content: "";
    display: block; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.embed-responsive-21by9:before {
  padding-top: 42.85714%; }

.embed-responsive-16by9:before {
  padding-top: 56.25%; }

.embed-responsive-4by3:before {
  padding-top: 75%; }

.embed-responsive-1by1:before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 30rem) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 48rem) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 62rem) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 75rem) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 8030; }

.fixed-bottom {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 8030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 8020; } }

.sr-only {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 0.0625rem !important;
  margin: -0.0625rem !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 0.0625rem !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.5rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.5rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important; }

.m-2 {
  margin: 1rem !important; }

.mt-2,
.my-2 {
  margin-top: 1rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1rem !important; }

.m-3 {
  margin: 2rem !important; }

.mt-3,
.my-3 {
  margin-top: 2rem !important; }

.mr-3,
.mx-3 {
  margin-right: 2rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 2rem !important; }

.ml-3,
.mx-3 {
  margin-left: 2rem !important; }

.m-4 {
  margin: 3rem !important; }

.mt-4,
.my-4 {
  margin-top: 3rem !important; }

.mr-4,
.mx-4 {
  margin-right: 3rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 3rem !important; }

.ml-4,
.mx-4 {
  margin-left: 3rem !important; }

.m-5 {
  margin: 4rem !important; }

.mt-5,
.my-5 {
  margin-top: 4rem !important; }

.mr-5,
.mx-5 {
  margin-right: 4rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 4rem !important; }

.ml-5,
.mx-5 {
  margin-left: 4rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.5rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.5rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.5rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.5rem !important; }

.p-2 {
  padding: 1rem !important; }

.pt-2,
.py-2 {
  padding-top: 1rem !important; }

.pr-2,
.px-2 {
  padding-right: 1rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1rem !important; }

.pl-2,
.px-2 {
  padding-left: 1rem !important; }

.p-3 {
  padding: 2rem !important; }

.pt-3,
.py-3 {
  padding-top: 2rem !important; }

.pr-3,
.px-3 {
  padding-right: 2rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 2rem !important; }

.pl-3,
.px-3 {
  padding-left: 2rem !important; }

.p-4 {
  padding: 3rem !important; }

.pt-4,
.py-4 {
  padding-top: 3rem !important; }

.pr-4,
.px-4 {
  padding-right: 3rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 3rem !important; }

.pl-4,
.px-4 {
  padding-left: 3rem !important; }

.p-5 {
  padding: 4rem !important; }

.pt-5,
.py-5 {
  padding-top: 4rem !important; }

.pr-5,
.px-5 {
  padding-right: 4rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 4rem !important; }

.pl-5,
.px-5 {
  padding-left: 4rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 30rem) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.5rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important; }
  .m-sm-2 {
    margin: 1rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important; }
  .m-sm-3 {
    margin: 2rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 2rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 2rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 2rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 2rem !important; }
  .m-sm-4 {
    margin: 3rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 3rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 3rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 3rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 3rem !important; }
  .m-sm-5 {
    margin: 4rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 4rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 4rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 4rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 4rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.5rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important; }
  .p-sm-2 {
    padding: 1rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important; }
  .p-sm-3 {
    padding: 2rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 2rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 2rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 2rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 2rem !important; }
  .p-sm-4 {
    padding: 3rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 3rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 3rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 3rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 3rem !important; }
  .p-sm-5 {
    padding: 4rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 4rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 4rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 4rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 4rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 48rem) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.5rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important; }
  .m-md-2 {
    margin: 1rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important; }
  .m-md-3 {
    margin: 2rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 2rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 2rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 2rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 2rem !important; }
  .m-md-4 {
    margin: 3rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 3rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 3rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 3rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 3rem !important; }
  .m-md-5 {
    margin: 4rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 4rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 4rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 4rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 4rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.5rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important; }
  .p-md-2 {
    padding: 1rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important; }
  .p-md-3 {
    padding: 2rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 2rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 2rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 2rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 2rem !important; }
  .p-md-4 {
    padding: 3rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 3rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 3rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 3rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 3rem !important; }
  .p-md-5 {
    padding: 4rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 4rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 4rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 4rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 4rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 62rem) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.5rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important; }
  .m-lg-2 {
    margin: 1rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important; }
  .m-lg-3 {
    margin: 2rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 2rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 2rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 2rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 2rem !important; }
  .m-lg-4 {
    margin: 3rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 3rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 3rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 3rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 3rem !important; }
  .m-lg-5 {
    margin: 4rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 4rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 4rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 4rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 4rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.5rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important; }
  .p-lg-2 {
    padding: 1rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important; }
  .p-lg-3 {
    padding: 2rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 2rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 2rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 2rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 2rem !important; }
  .p-lg-4 {
    padding: 3rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 3rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 3rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 3rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 3rem !important; }
  .p-lg-5 {
    padding: 4rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 4rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 4rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 4rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 4rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 75rem) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.5rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important; }
  .m-xl-2 {
    margin: 1rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important; }
  .m-xl-3 {
    margin: 2rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 2rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 2rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 2rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 2rem !important; }
  .m-xl-4 {
    margin: 3rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 3rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 3rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 3rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 3rem !important; }
  .m-xl-5 {
    margin: 4rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 4rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 4rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 4rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 4rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.5rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important; }
  .p-xl-2 {
    padding: 1rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important; }
  .p-xl-3 {
    padding: 2rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 2rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 2rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 2rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 2rem !important; }
  .p-xl-4 {
    padding: 3rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 3rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 3rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 3rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 3rem !important; }
  .p-xl-5 {
    padding: 4rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 4rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 4rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 4rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 4rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.text-cancel {
  color: #DEE2E6 !important; }

.text-danger {
  color: #DC3545 !important; }

.text-dark {
  color: #343A40 !important; }

.text-info {
  color: #17A2B8 !important; }

.text-light {
  color: #F8F9FA !important; }

.text-primary {
  color: #EF8333 !important; }

.text-secondary {
  color: #EADEBD !important; }

.text-success {
  color: #90AD25 !important; }

.text-warning {
  color: #FFC107 !important; }

.text-white {
  color: #FFF !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-muted {
  color: #868E96 !important; }

.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }

.text-break {
  overflow-wrap: break-word !important;
  word-break: break-word !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.jq-toast-wrap {
  bottom: 0;
  display: block;
  letter-spacing: normal;
  padding-left: 3rem;
  padding-right: 3rem;
  pointer-events: none;
  position: fixed;
  right: 0;
  width: 37.5rem;
  z-index: 9000; }

.jq-toast-single {
  background-color: #EADEBD;
  color: #FFF;
  display: block;
  margin: 0.3125rem 0 0;
  padding: 0.625rem 2.5rem 0.625em 1.25rem;
  pointer-events: all;
  position: relative;
  width: 100%; }

.jq-toast-loader {
  background: #EF8333 !important;
  display: block;
  height: 0.25rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 0; }

.jq-toast-loaded {
  width: 100%; }

.jq-has-icon {
  background-position: 0.625rem;
  background-repeat: no-repeat;
  padding-left: 2.75rem; }

.jq-icon-success {
  background-color: #90AD25;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%23FFFFFF' d='M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z' /></svg>");
  background-position: left 0.625rem center;
  background-repeat: no-repeat;
  background-size: 1.5rem; }
  .jq-icon-success .jq-toast-loader {
    background: #5c6e18 !important; }

.jq-icon-error {
  background-color: #DC3545;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%23FFFFFF' d='M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' /></svg>");
  background-position: left 0.625rem center;
  background-repeat: no-repeat;
  background-size: 1.5rem; }
  .jq-icon-error .jq-toast-loader {
    background: #a71d2a !important; }

.close-jq-toast-single {
  cursor: pointer;
  display: block;
  font-size: 1.125rem;
  height: 1.125rem;
  line-height: 1.125rem;
  position: absolute;
  right: 0.625rem;
  text-align: center;
  top: 0.3125rem;
  width: 1.125rem; }

body {
  font-size: .875rem;
  font-family: 'Open Sans', sans-serif; }

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom; }

/*
 * Sidebar
 */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 35px 0 0;
  /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1); }

.sidebar-sticky {
  position: relative;
  background-color: #D5D2D2;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */ }

@supports (position: -webkit-sticky) or (position: sticky) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky; } }

.sidebar .nav-link {
  font-weight: 500;
  color: #333; }

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999; }

.sidebar .nav-link.active {
  border-right: solid; }

.sidebar .nav-link:hover .feather {
  color: #343a40 !important; }

.sidebar .nav-link.active .feather {
  color: inherit; }

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase; }

/*
 * Content
 */
[role="main"] {
  padding-top: 20px;
  /* Space for fixed navbar */ }

/*
 * Navbar
 */
.navbar-brand {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  color: #343a40 !important;
  font-size: 1rem; }

.navbar {
  background-color: #e5ebbb; }

.navbar-nav a {
  color: #343a40 !important; }

.nav a:hover {
  background-color: #C6D8FF; }

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0; }

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1); }

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25); }

/*
login form
 */
.login-popup-heading > h4 {
  color: #343a40;
  font-size: 14px;
  line-height: 30px; }

.new_reg_popup, .new_login_popup {
  border-radius: 2px;
  min-height: 332px;
  width: 500px; }

.login-popup-btn {
  background: #343a40;
  border: none;
  border-radius: 25px;
  color: #fff;
  display: block;
  font-size: 14px;
  height: 38px;
  line-height: 28px;
  margin: 20px auto 5px;
  width: 150px;
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease; }

a {
  color: #343a40;
  font-size: 14px; }

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%; }

body {
  margin-bottom: 60px;
  /* Margin bottom by footer height */ }

.footer {
  position: absolute;
  text-align: right;
  bottom: 0;
  width: 100%;
  height: 40px;
  /* Set the fixed height of the footer here */
  line-height: 40px;
  /* Vertically center the text there */
  background-color: #f5f5f5; }

/* Message Tags */
#messages {
  width: 100%;
  background-color: gray;
  color: white;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin-left: auto !important; }

#messages.success {
  background-color: green; }

#messages.error {
  background-color: red; }

/*custom datatable styles*/
#table_bookings td, #table_quality-stat td, #table-bookings_canceled td {
  cursor: pointer !important; }

#table_bookings tr:hover, #table_quality-stat tr:hover, #table-bookings_canceled tr:hover {
  background-color: #4b7bff;
  position: relative;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset; }

#table_quality-stat tr.selected {
  background-color: darkseagreen; }

table {
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777; }

.project_display {
  display: None; }

table.dataTable th {
  background-color: #e5ebbb; }

table.dataTable a[data-edit]:hover, table.dataTable a[data-delete]:hover, table.dataTable a[data-download]:hover, table.dataTable a[data-link-assesement]:hover {
  cursor: pointer;
  background-color: rgba(23, 162, 184, 0.5); }

table.dataTable a img {
  height: 20px !important; }

/*menu*/
/* On mouse-over */
/* Main content */
.main {
  margin-left: 200px;
  /* Same as the width of the sidenav */
  font-size: 20px;
  /* Increased text to enable scrolling */
  padding: 0px 10px; }

/* Add an active class to the active dropdown button */
nav .active {
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.44);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.44);
  background-color: white; }

#myTab li.nav-item a.active {
  background-color: #D5D2D2;
  border-bottom: #007bff;
  border-bottom-style: solid; }

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  display: none;
  padding-left: 8px; }

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px; }

.dropdown-item.active, .dropdown-item:active {
  font-weight: bold; }

#dropdownbtn {
  cursor: pointer; }

.fa-times-circle {
  color: red; }

.fa-check-circle {
  color: green; }

/*delete modal*/
#delete_modal {
  text-align: center; }

#delete_modal .modal-header {
  background-color: #e5ebbb; }

#delete_modal .modal-body {
  padding-top: inherit; }

#actions a:hover {
  background-color: transparent; }

.img-thumbnail {
  width: 100px;
  height: 80px; }

#table_booking_detail tbody tr td {
  padding-right: 8px;
  font-size: 1em; }

.is_active {
  color: blue; }

.is_canceled {
  color: red; }

.is_confirmed {
  color: green; }

.dt-button {
  background-color: #1A01CC !important; }

/*confirmation*/
#confirmation_form {
  padding: 0px !important; }

.link {
  fill: none;
  stroke: #000;
  stroke-opacity: .2; }

.link:hover {
  stroke-opacity: .5; }

.node text {
  pointer-events: none;
  text-shadow: 0 1px 0 #fff;
  font-size: 7pt; }

.overlay, .sideMenu {
  bottom: 0;
  position: fixed; }

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  left: -100%;
  margin: auto;
  right: 100%;
  top: 0;
  transition: all ease 0.1s;
  z-index: 998; }

.overlay.open {
  left: 0;
  right: 0; }

.sidebarNavigation {
  justify-content: flex-start;
  margin-bottom: 0;
  z-index: 999; }

.sidebarNavigation .leftNavbarToggler {
  margin-right: 10px;
  order: -1; }

.sideMenu {
  left: -100%;
  margin-bottom: 0;
  max-width: 80%;
  overflow: hidden;
  padding: 1rem;
  top: 52px;
  transition: all ease 0.1s;
  z-index: 999; }

.sideMenu.open {
  display: block;
  left: 0;
  overflow-y: auto; }

.sideMenu ul {
  margin: 0;
  padding: 0 15px; }

.nav a:hover {
  background-color: lightslategray; }

html {
  min-height: 100%;
  position: relative; }

body {
  margin-bottom: 60px;
  /* Margin bottom by footer height */ }

.footer {
  background-color: #f5f5f5;
  bottom: 0;
  height: 20px;
  /* Set the fixed height of the footer here */
  line-height: 20px;
  /* Vertically center the text there */
  position: fixed;
  text-align: right;
  width: 100%; }

.btn-new {
  width: inherit !important; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  background-color: #EADEBD;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  position: relative; }

section {
  overflow-y: auto !important;
  padding: 20px 0; }

section .section-title {
  color: #007b5e;
  margin-bottom: 50px;
  text-align: center;
  text-transform: uppercase; }

a {
  color: #000;
  font-family: "Open Sans", sans-serif;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s; }

a:hover {
  color: #000;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s; }

h1, h2, h3, h4, h5 {
  font-family: "Open Sans", sans-serif; }

p, ul, li {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 25px; }

.form-inline .form-control.custom-file-upload {
  border: 1px solid #ccc;
  cursor: pointer;
  overflow: hidden;
  padding: 6px 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

#booking_form .form-control, #config-form .form-control {
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.24); }

.wrap-input-container {
  display: inline-block;
  overflow: hidden;
  position: relative; }

.wrap-input-container input {
  font-size: 400px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1; }

a[data-cancel=""], a[data-print=""], a[data-edit=""] {
  color: white !important; }

#cancel_modal .modal-body {
  padding: 0em 1em 1em 1em !important; }

/* sidenav */
.sidebar-sticky {
  height: calc(100vh - 36px); }

.custom-box-shadow {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75); }

.navbar {
  background-color: #EADEBD; }

.custom-background {
  background-color: #EADEBD; }

.nav a:hover {
  background-color: #C6D8FF; }

table.dataTable th {
  background-color: #EADEBD; }

.paginate_button {
  padding-left: 0 !important;
  padding-right: 0 !important; }

/*
colors:
header: #9CE074
active: #FFFFFF
sidenav: #D5D2D2
hover: #C6D8FF
button: #71A9F7
 */
*,
*:before,
*:after {
  box-sizing: border-box; }

.row-link {
  cursor: pointer; }

main {
  display: block; }

.icon {
  display: block;
  height: 1.5rem;
  width: 1.5rem; }

@media (min-width: 62rem) {
  .login {
    min-height: 100vh; } }

.dz-remove {
  margin-top: 0.5rem; }

.dz-button {
  color: #555555; }
