body {
    font-size: .875rem;
    font-family: 'Open Sans', sans-serif;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 35px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
    position: relative;
    background-color: #D5D2D2;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}


.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
}

.sidebar .nav-link.active {
    border-right: solid;
}

.sidebar .nav-link:hover .feather {
    color: #343a40 !important;
}

.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
    padding-top: 20px; /* Space for fixed navbar */
}

/*
 * Navbar
 */

.navbar-brand {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    color: #343a40 !important;
    font-size: 1rem;
}

.navbar {
    background-color: #e5ebbb;
}

.navbar-nav a {
    color: #343a40 !important;
}

.nav a:hover {
    background-color: #C6D8FF;
}

.navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

/*
login form
 */

.login-popup-heading > h4 {
    color: #343a40;
    font-size: 14px;
    line-height: 30px;
}

.new_reg_popup, .new_login_popup {
    border-radius: 2px;
    min-height: 332px;
    width: 500px;
}

.login-popup-btn {
    background: #343a40;
    border: none;
    border-radius: 25px;
    color: #fff;
    display: block;
    font-size: 14px;
    height: 38px;
    line-height: 28px;
    margin: 20px auto 5px;
    width: 150px;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
}

a {
    color: #343a40;
    font-size: 14px;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 60px; /* Margin bottom by footer height */
}

.footer {
    position: absolute;
    text-align: right;
    bottom: 0;
    width: 100%;
    height: 40px; /* Set the fixed height of the footer here */
    line-height: 40px; /* Vertically center the text there */
    background-color: #f5f5f5;
}

/* Message Tags */
#messages {
    width: 100%;
    background-color: gray;
    color: white;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-left: auto !important;
}

#messages.success {
    background-color: green;
}

#messages.error {
    background-color: red;
}

/*custom datatable styles*/
#table_bookings td, #table_quality-stat td , #table-bookings_canceled td{
    cursor: pointer !important;
}

#table_bookings tr:hover, #table_quality-stat tr:hover, #table-bookings_canceled tr:hover {
    background-color: #4b7bff;
    position: relative;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;

}

#table_quality-stat tr.selected {
    background-color: darkseagreen;
}

table {
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
}

.project_display {
    display: None;
}

table.dataTable th {
    background-color: #e5ebbb;
}

table.dataTable a[data-edit]:hover, table.dataTable a[data-delete]:hover, table.dataTable a[data-download]:hover, table.dataTable a[data-link-assesement]:hover {
    cursor: pointer;
    background-color: rgba(23, 162, 184, .5);
}

table.dataTable a img {
    height: 20px !important;
}

/*menu*/
/* On mouse-over */
.sidenav a:hover, .dropdown-btn:hover {
}

/* Main content */
.main {
    margin-left: 200px; /* Same as the width of the sidenav */
    font-size: 20px; /* Increased text to enable scrolling */
    padding: 0px 10px;
}

/* Add an active class to the active dropdown button */
nav .active {
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.44);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.44);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.44);
    background-color: white;
}

#myTab li.nav-item a.active {
    background-color: #D5D2D2;
    border-bottom: #007bff;
    border-bottom-style: solid;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
    display: none;
    padding-left: 8px;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
    float: right;
    padding-right: 8px;
}

.dropdown-item.active, .dropdown-item:active {
    font-weight: bold;
}

#dropdownbtn {
    cursor: pointer;
}

.fa-times-circle {
    color: red;
}

.fa-check-circle {
    color: green;
}

/*delete modal*/
#delete_modal {
    text-align: center;
}

#delete_modal .modal-header {
    background-color: #e5ebbb;
}

#delete_modal .modal-body {
    padding-top: inherit;
}

#actions a:hover {
    background-color: transparent;
}

.img-thumbnail {
    width: 100px;
    height: 80px;
}

#table_booking_detail tbody tr td {
    padding-right: 8px;
    font-size: 1em;
}

.is_active {
    color: blue;
}

.is_canceled {
    color: red;
}

.is_confirmed {
    color: green;
}

.dt-button {
    background-color: #1A01CC !important;
}

/*confirmation*/
#confirmation_form {
    padding: 0px !important;
}
.link {
  fill: none;
  stroke: #000;
  stroke-opacity: .2;
}
.link:hover {
  stroke-opacity: .5;
}

.node text {
  pointer-events: none;
  text-shadow: 0 1px 0 #fff;
    font-size: 7pt;
}

