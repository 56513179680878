//------------------------------------------------------------------------------
// RESETS

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legend {
  color: inherit;
  display: block;
  font-size: rem(18);
  line-height: inherit;
  margin-bottom: rem(8);
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%;
}

input {
  border-radius: 0;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: visible;

  &:disabled {
    cursor: not-allowed;
  }

  &[type="search"] {
    appearance: none;
    outline-offset: rem(-2);

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }

  &[type="date"],
  &[type="datetime-local"],
  &[type="time"],
  &[type="month"] {
    appearance: listbox;
    cursor: text;
    min-width: 100%; // iOS-Bugfix
  }

  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    pointer-events: none;
    visibility: hidden;
  }

  &::-webkit-clear-button,
  &::-ms-clear {
    display: none;
  }

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }
}

select {
  border-radius: 0;
  cursor: pointer;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
  text-transform: none;
  word-wrap: normal;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    &::-ms-value {
      background: transparent;
    }
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

optgroup {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: auto;
  resize: vertical;

  &[disabled] {
    cursor: not-allowed;
  }

  &.form-control {
    height: auto;
  }
}

button {
  border-radius: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: hidden;
  text-transform: none;
  user-select: none;

  &:focus {
    outline: rem(1) dotted;
  }
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button,
[type="reset"],
[type="submit"] {
  appearance: button;
}


//------------------------------------------------------------------------------
// INPUTS

.form-group {
  margin-bottom: rem(16);
}

%form-control {
  background-color: $white;
  border: rem($border-width) solid $input-border-color;
  border-radius: rem($border-radius);
  color: $gray-700;
  font-size: rem($input-font-size);
  height: rem(39);
  transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
  width: 100%;

  &:focus {
    //background-color: $white;
    //border-color: $input-border-focus;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    //color: $gray-700;
    //outline: 0;
  }
}

%custom-control {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='#{$gray-800}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>"));
  background-position: right rem(12) center;
  background-repeat: no-repeat;
  background-size: rem(8) rem(10);
}

.form-control {
  @extend %form-control;
  background-clip: padding-box;
  display: block;
  padding: rem(6) rem(10);

  &:disabled,
  &[readonly] {
    background-color: $gray-200;
    color: $gray-600;
    opacity: 1;  // iOS fix for unreadable disabled content.
  }

  &::placeholder {
    color: $gray-600;
    opacity: 1;  // Override Firefox's unusual default opacity
  }
}

.form-control-sm {
  font-size: rem($input-font-size-sm);
  height: rem(32);
  padding: rem(2) rem(6);
}

.custom-date,
.custom-time,
.custom-number {
  @extend %custom-control;
}

.custom-date {
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 0;
  }
}

.custom-time,
.custom-number {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    cursor: pointer;
    opacity: 0;
  }
}

.custom-control {
  display: block;
  min-height: rem($input-font-size * $line-height-base);
  padding-left: rem($custom-control-gutter + $custom-control-indicator-size);
  position: relative;
}

.custom-control-label {
  margin-bottom: 0;
  position: relative;
  vertical-align: top;

  // Input

  &:before {
    background-color: $white;
    border: rem($border-width) solid $input-border-color;
    content: "";
    display: block;
    height: rem($custom-control-indicator-size);
    left: rem(-($custom-control-indicator-size + $custom-control-gutter));
    pointer-events: none;
    position: absolute;
    top: rem(($input-font-size * $line-height-base - $custom-control-indicator-size) / 2);
    transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
    width: rem($custom-control-indicator-size);
  }

  // Indicator

  &:after {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 50%;
    content: "";
    display: block;
    height: rem($custom-control-indicator-size);
    left: rem(-($custom-control-indicator-size + $custom-control-gutter));
    position: absolute;
    top: rem(($input-font-size * $line-height-base - $custom-control-indicator-size) / 2);
    width: rem($custom-control-indicator-size);
  }
}

.custom-control-input {
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked ~ .custom-control-label:before {
    background: $input-border-focus;
    border-color: $input-border-focus;
  }

  &:focus ~ .custom-control-label:before {
    border-color: $input-border-focus;
    box-shadow: 0 0 0 rem(3) rgba($input-border-focus, 0.5);
  }

  &[disabled] ~ .custom-control-label {
    color: $gray-600;

    &:before {
      background-color: $gray-200;
    }
  }
}

.custom-checkbox {
  .custom-control-label:before {
    border-radius: rem($border-radius);
  }

  .custom-control-input:checked ~ .custom-control-label {
    &:after {
      background-image: escape-svg(url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$white}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>"));
    }
  }
}

.custom-radio {
  .custom-control-label:before {
    border-radius: 50%;
  }

  .custom-control-input:checked ~ .custom-control-label {
    &:after {
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$white}'/></svg>"));
    }
  }
}

.custom-select {
  @extend %form-control;
  @extend %custom-control;
  appearance: none;
  display: inline-block;
  height: rem(32);
  line-height: rem(20);
  padding: rem(6) rem(28) rem(6) rem(12);
  vertical-align: middle;

  &:focus {
    &::-ms-value {
      background-color: $white;
      color: $gray-700;
    }
  }

  &:disabled {
    background-color: $gray-200;
    color: $gray-600;
  }

  &::-ms-expand {
    display: none;  // Hides the default caret in IE11
  }

  &[multiple],
  &[size]:not([size="1"]) {
    background-image: none;
    height: auto;
    padding-right: rem(12);
  }
}

.custom-select-sm {
  background-position: right rem(8) center;
  font-size: rem($input-font-size-sm);
  height: rem(32);
  line-height: rem(26);
  padding: rem(2) rem(22) rem(2) rem(6);
}

.custom-control-inline {
  display: inline-flex;
  margin-right: rem(16);
}

.form-text {
  display: block;
  margin-top: rem($form-text-margin-top);

  ~ .invalid-feedback {
    margin: 0;
  }
}


//------------------------------------------------------------------------------
// VALIDATION

.invalid-feedback {
  color: $danger;
  display: none;
  margin-top: rem($form-feedback-margin-top);
  width: 100%;
}

.form-control.is-invalid {
  border-color: $danger;

  &:focus {
    border-color: $danger;
    box-shadow: 0 0 0 rem(3) rgba($danger, 0.25);
  }

  ~ .invalid-feedback {
    display: block;
  }
}

.form-group.is-invalid {
  .select2-container.select2-container--bootstrap , .select2-selection {
    border-color: $danger;

    &:focus {
      border-color: $danger;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($danger, 0.6);
    }

    ~ .invalid-feedback {
      display: block;
    }
  }
}

.form-group.is-valid {
  .select2-container.select2-container--bootstrap , .select2-selection {
    border-color: #28a745;
    &:focus {
      border-color: #66afe9;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
    ~ .invalid-feedback {
      display: block;
    }
  }
}

.custom-control-input {
  .was-validated &:invalid,
  &.is-invalid {
    ~ .custom-control-label {
      color: $danger;

      &:before {
        border-color: $danger;
      }
    }

    ~ .invalid-feedback {
      display: block;
    }

    &:checked {
      ~ .custom-control-label:before {
        border-color: lighten($danger, 10%);
      }
    }

    &:focus {
      ~ .custom-control-label:before {
        box-shadow: 0 0 0 rem(3) rgba($danger, 0.25);
      }

      &:not(:checked) ~ .custom-control-label:before {
        border-color: $danger;
      }
    }
  }
}

.custom-file-input {
  &.is-invalid {
    ~ .custom-file-label {
      border-color: $danger;
    }

    ~ .invalid-feedback {
      display: block;
    }

    &:focus {
      ~ .custom-file-label {
        border-color: $danger;
        box-shadow: 0 0 0 rem(3) rgba($danger, 0.25);
      }
    }
  }
}

.error {
  border-color: #DC3545 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(220, 53, 69, 0.6) !important;
}

label.error {
  border-color: transparent !important;
  box-shadow: none !important;
  color: #DC3545;
}

.select2-selection.select2-selection--multiple {
  min-height: 2.3rem;
}
