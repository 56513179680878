//------------------------------------------------------------------------------
// TOOLTIP

.tooltip {
  @include reset-text();
  display: block;
  font-size: rem(14);
  margin: rem(2);
  opacity: 0;
  position: absolute;
  word-wrap: break-word;
  z-index: 9000;

  &.show {
    opacity: 1;
  }

  .tooltip-arrow {
    display: block;
    height: rem(8);
    position: absolute;
    width: rem(12);

    &:before {
      border-color: transparent;
      border-style: solid;
      content: "";
      position: absolute;
    }
  }
}

.bs-tooltip-top {
  padding: rem(8) 0;

  .tooltip-arrow {
    bottom: 0;

    &:before {
      border-top-color: $dark;
      border-width: rem(8) rem(6) 0;
      top: 0;
    }
  }
}

.bs-tooltip-right {
  padding: 0 rem(8);

  .tooltip-arrow {
    height: rem(8);
    left: 0;
    width: rem(8);

    &:before {
      border-right-color: $dark;
      border-width: rem(6) rem(8) rem(6) 0;
      right: 0;
      top: rem(-2);
    }
  }
}

.bs-tooltip-bottom {
  padding: rem(8) 0;

  .tooltip-arrow {
    top: 0;

    &:before {
      border-bottom-color: $dark;
      border-width: 0 rem(6) rem(8);
      bottom: 0;
    }
  }
}

.bs-tooltip-left {
  padding: 0 rem(8);

  .tooltip-arrow {
    height: rem(8);
    right: 0;
    top: rem(-2);
    width: rem(8);

    &:before {
      border-left-color: $dark;
      border-width: rem(6) 0 rem(6) rem(8);
      left: 0;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }

  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }

  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }

  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}

.tooltip-inner {
  background-color: $dark;
  border-radius: rem($border-radius);
  color: color-yiq($dark);
  max-width: rem(200);
  padding: rem(3) rem(6);
  text-align: center;
}
