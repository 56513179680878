/* sidenav */

.sidebar-sticky {
  height: calc(100vh - 36px);
}

.custom-box-shadow {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.navbar {
  background-color: $secondary;
}

.custom-background {
  background-color: $secondary;
}

.nav a:hover {
  background-color: #C6D8FF;
}

table.dataTable th {
  background-color: $secondary;
}

.paginate_button {
  padding-left: 0 !important;
  padding-right: 0 !important;
}


/*
colors:
header: #9CE074
active: #FFFFFF
sidenav: #D5D2D2
hover: #C6D8FF
button: #71A9F7
 */
