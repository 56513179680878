//------------------------------------------------------------------------------
// IMPORTS

@import "functions";
@import "mixins";
@import "variables";

@import "alert";
@import "badge";
@import "buttons";
@import "card";
@import "dropdown";
@import "forms";
@import "grid";
@import "images";
@import "list-group";
@import "modal";
@import "nav";
@import "navbar";
@import "pagination";
@import "progress";
@import "tables";
@import "toasts";
@import "tooltip";
@import "transitions";
@import "type";
@import "utilities";

//@import "datatables";
@import "toast";

@import "dashboard";
@import "mobilebar";
//@import "datatable";
@import "custom";


//------------------------------------------------------------------------------
// DEFAULTS

*,
*:before,
*:after {
  box-sizing: border-box;
}


//------------------------------------------------------------------------------
// DATATABLE

.row-link {
  cursor: pointer;
}

//------------------------------------------------------------------------------
// MAIN

main {
  display: block;
}


//------------------------------------------------------------------------------
// ICON

.icon {
  display: block;
  height: rem(24);
  width: rem(24);
}


//------------------------------------------------------------------------------
// LOGIN

@include media-breakpoint-up(lg) {
  .login {
    min-height: 100vh;
  }
}

//------------------------------------------------------------------------------
// Dropzone anpassungen

.dz-remove {
  margin-top: 0.5rem;
}

.dz-button {
  color: #555555;
}
