// stylelint-disable declaration-no-important

// ------------------------------------------------------------------------------
// TOAST

.jq {
  &-toast {
    &-wrap {
      bottom: 0;
      display: block;
      letter-spacing: normal;
      padding-left: rem(48);
      padding-right: rem(48);
      pointer-events: none;
      position: fixed;
      right: 0;
      width: rem(600);
      z-index: 9000;
    }

    &-single {
      background-color: $secondary;
      color: $white;
      display: block;
      margin: rem(5) 0 0;
      padding: rem(10) rem(40) em(10) rem(20);
      pointer-events: all;
      position: relative;
      width: 100%;
    }

    &-loader {
      background: $primary!important;
      display: block;
      height: rem(4);
      left: 0;
      position: absolute;
      top: 0;
      width: 0;
    }

    &-loaded {
      width: 100%;
    }
  }

  &-has-icon {
    background-position: rem(10);
    background-repeat: no-repeat;
    padding-left: rem(44);
  }

  &-icon-success {
    background-color: $success;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%23FFFFFF' d='M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z' /></svg>");
    background-position: left rem(10) center;
    background-repeat: no-repeat;
    background-size: rem(24);

    .jq-toast-loader {
      background: darken($success, 15%)!important;
    }
  }

  &-icon-error {
    background-color: $danger;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%23FFFFFF' d='M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' /></svg>");
    background-position: left rem(10) center;
    background-repeat: no-repeat;
    background-size: rem(24);

    .jq-toast-loader {
      background: darken($danger, 15%)!important;
    }
  }
}

.close-jq-toast-single {
  cursor: pointer;
  display: block;
  font-size: rem(18);
  height: rem(18);
  line-height: rem(18);
  position: absolute;
  right: rem(10);
  text-align: center;
  top: rem(5);
  width: rem(18);
}
