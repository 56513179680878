
.overlay , .sideMenu {
  bottom: 0;
  position: fixed;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  left: -100%;
  margin: auto;
  right: 100%;
  top: 0;
  transition: all ease 0.1s;
  z-index: 998;
}

.overlay.open {
  left: 0;
  right: 0;
}

.sidebarNavigation {
  justify-content: flex-start;
  margin-bottom: 0;
  z-index: 999;
}

.sidebarNavigation .leftNavbarToggler {
  margin-right: 10px;
  order: -1;
}

.sideMenu {
  left: -100%;
  margin-bottom: 0;
  max-width: 80%;
  overflow: hidden;
  padding: 1rem;
  top: 52px;
  transition: all ease 0.1s;
  z-index: 999;
}

.sideMenu.open {
  display: block;
  left: 0;
  overflow-y: auto;
}

.sideMenu ul {
  margin: 0;
  padding: 0 15px;
}

.nav a:hover {
  background-color: lightslategray;
}

html {
  min-height: 100%;
  position: relative;
}

body {
  margin-bottom: 60px; /* Margin bottom by footer height */
}

.footer {
  background-color: #f5f5f5;
  bottom: 0;
  height: 20px; /* Set the fixed height of the footer here */
  line-height: 20px; /* Vertically center the text there */
  position: fixed;
  text-align: right;
  width: 100%;
}

.btn-new {
  width: inherit!important;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: $secondary;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  position: relative;
}

section {
  overflow-y: auto!important;
  padding: 20px 0;
}

section .section-title {
  color: #007b5e;
  margin-bottom: 50px;
  text-align: center;
  text-transform: uppercase;
}

a {
  color: #000;
  font-family: "Open Sans", sans-serif;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

a:hover {
  color: #000;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

h1 , h2 , h3 , h4 , h5 {
  font-family: "Open Sans", sans-serif;
}

p , ul , li {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 25px;
}

.form-inline .form-control.custom-file-upload {
  border: 1px solid #ccc;
  cursor: pointer;
  overflow: hidden;
  padding: 6px 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

#booking_form .form-control, #config-form .form-control {
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.24);
}

.wrap-input-container {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.wrap-input-container input {
  font-size: 400px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

a[data-cancel=""] , a[data-print=""] , a[data-edit=""] {
  color: white!important;
}

#cancel_modal .modal-body {
  padding: 0em 1em 1em 1em!important;
}
