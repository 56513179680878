//------------------------------------------------------------------------------
// PAGINATION

//.pagination {
//  border-radius: rem($border-radius);
//  display: flex;
//  @include list-unstyled();
//}

.page-link {
  background-color: $white;
  border: rem($border-width) solid #DDD;
  color: $gray-900;
  cursor: pointer;
  display: block;
  line-height: 1.25;
  margin-left: rem(-1);
  padding: rem(8) rem(12);
  position: relative;
  user-select: none;

  &:hover {
    background-color: $gray-200;
    border-color: #DDD;
    color: $gray-900;
    text-decoration: none;
    z-index: 2;
  }

  &:focus {
    border-color: $secondary;
    box-shadow: 0 0 0 rem(3) rgba($secondary, 0.5);
    outline: 0;
    z-index: 3;
  }
}

.page-item {
  &.active .page-link {
    background-color: $secondary;
    border-color: $secondary;
    color: $gray-900;
    z-index: 3;
  }

  //&.disabled .page-link {
  //  background-color: $white;
  //  border-color: #ddd;
  //  color: $gray-600;
  //  cursor: auto;
  //  pointer-events: none;
  //}
}
