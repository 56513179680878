//------------------------------------------------------------------------------
// CARD

.card {
  background-clip: border-box;
  background-color: $white;
  border: rem($border-width) solid $gray-500;
  border-radius: rem($border-radius);
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  word-wrap: break-word;

  > .list-group:first-child {
    .list-group-item:first-child {
      border-radius: rem($border-radius) rem($border-radius) 0 0;
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      border-radius: 0 0 rem($border-radius) rem($border-radius);
    }
  }
}

.card-header {
  background-color: $gray-200;
  border-bottom: rem($border-width) solid $gray-500;
  border-radius: rem($border-radius) rem($border-radius) 0 0;
  margin-bottom: 0;
  padding: rem(16) rem(20);
}

.card-body {
  flex: 1 1 auto;
  padding: rem(16) rem(20);
}

.card-title {
  margin-bottom: rem(12);
}
