//------------------------------------------------------------------------------
// PROGRESS

.progress {
  background-color: $gray-200;
  border-radius: rem($border-radius);
  display: flex;
  font-size: rem(12);
  height: rem(16);
  overflow: hidden;
}

.progress-bar {
  background-color: $secondary;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: width 600ms ease;
  white-space: nowrap;
}
