//------------------------------------------------------------------------------
// COLORS

$white: #FFF;
$black: #000;

$gray-100: #F8F9FA;
$gray-200: #E9ECEF;
$gray-300: #DEE2E6;
$gray-400: #CED4DA;
$gray-500: #ADB5BD;
$gray-600: #868E96;
$gray-700: #495057;
$gray-800: #343A40;
$gray-900: #212529;

$body-color: $gray-900;

$primary: #EF8333; // X-NET Services
//$primary: #196FAA; // X-NET Technologies
$secondary: #EADEBD;
$success: #90AD25;
$info: #17A2B8;
$warning: #FFC107;
$danger: #DC3545;
$light: $gray-100;
$dark: $gray-800;

// Colors

$color-interval: 8%;

$colors: (
  "cancel": $gray-300,
  "danger": $danger,
  "dark": $dark,
  "info": $info,
  "light": $light,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "warning": $warning,
  "white": $white,
);

// YIQ color contrast

$yiq-contrasted-threshold: 150;
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;


//------------------------------------------------------------------------------
// TYPE

$small-font-size: 87.5%;

$min-font-size: 14;
$max-font-size: 15;

$h1-min-font-size: 27;
$h1-max-font-size: 38;
$h2-min-font-size: 22;
$h2-max-font-size: 28;
$h3-min-font-size: 18;
$h3-max-font-size: 21;
$h4-min-font-size: 14;
$h4-max-font-size: 16;
$h5-min-font-size: 14;
$h5-max-font-size: 16;

$input-font-size-sm: 14;
$input-font-size: 16;

$line-height-base: 1.5;


//------------------------------------------------------------------------------
// FORMS

$custom-control-indicator-size: 16;
$custom-control-gutter: 8;

$input-color: $gray-700;
$input-bg: $white;
$input-border-color: $gray-500;
$input-border-focus: $secondary;
$input-border-width: 1;
$input-disabled-bg: $gray-200;
$input-focus-box-shadow: 0 0 0 rem(3) rgba($input-border-focus, 0.5);
$input-height-border: $input-border-width * 2;
$input-line-height: 1.5;
$input-padding-x: 12;
$input-padding-y: 6;

$input-height-inner: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2});
$input-height: calc(#{$input-line-height * 1em} + #{rem($input-padding-y * 2)} + #{rem($input-height-border)});

$form-text-margin-top: 4;
$form-feedback-margin-top: 4;

$custom-file-text: (
  de: "Durchsuchen",
  en: "Browse",
);

// Button

$input-btn-padding-y: 6;
$input-btn-padding-x: 10;


//------------------------------------------------------------------------------
// TABLE

$table-hover-color: $gray-900;
$table-hover-bg: $gray-400;


//------------------------------------------------------------------------------
// BREAKPOINTS

$body-min-width: rem(320);

$break-sm: 480;
$break-md: 768;
$break-lg: 992;
$break-xl: 1200;

$grid-breakpoints: (
  xs: 0,
  sm: rem($break-sm),
  md: rem($break-md),
  lg: rem($break-lg),
  xl: rem($break-xl),
);


//------------------------------------------------------------------------------
// GRID

$grid-columns: 12;
$grid-gutter-width: 30;

$grid-min-gutter-width: 15;
$grid-max-gutter-width: 60;

$container-sm-max-width: $break-sm;
$container-md-max-width: $break-md;
$container-lg-max-width: $break-lg;
$container-xl-max-width: $break-xl;

$container-max-widths: (
  xs: 0,
  sm: rem($container-sm-max-width),
  md: rem($container-md-max-width),
  lg: rem($container-lg-max-width),
  xl: rem($container-xl-max-width),
);


//------------------------------------------------------------------------------
// UTILITIES

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;

$escaped-characters: (
  ("<","%3c"),
  (">","%3e"),
  ("#","%23"),
);

// Embed

$embed-responsive-aspect-ratios: (
  (21 9),
  (16 9),
  (4 3),
  (1 1),
);

// Sizing

$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto,
);

// Spacing

$spacer: 8;

$spacers: (
  0: 0,
  1: rem($spacer),
  2: rem($spacer * 2),
  3: rem($spacer * 4),
  4: rem($spacer * 6),
  5: rem($spacer * 8),
);



//------------------------------------------------------------------------------
// COMPONENTS

$box-shadow: 0 rem(8) rem(16) rgba($black, 0.15);  // TODO: Überall verwenden!
$border-radius: 4;
$border-width: 1;

// Alert

$alert-margin-bottom: 16;
$alert-padding-x: 20;
$alert-padding-y: 12;

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;

// Dropdowns

$dropdown-bg: $white;
$dropdown-border-color: $gray-400;
$dropdown-color: $body-color;
$dropdown-divider-bg: $gray-200;
$dropdown-divider-margin-y: $spacer / 2;
$dropdown-header-color: $gray-600;
$dropdown-item-padding-x: 24;
$dropdown-item-padding-y: 4;
$dropdown-link-active-bg: $primary;
$dropdown-link-active-color: $white;
$dropdown-link-color: $gray-900;
$dropdown-link-disabled-color: $gray-600;
$dropdown-link-hover-bg: $gray-200;
$dropdown-link-hover-color: darken($gray-900, 5%);
$dropdown-padding-y: 8;

// Navbar

$nav-link-padding-x: 16;
$nav-link-padding-y: 8;
$navbar-nav-link-padding-x: 8;
$navbar-padding-x: 0;
$navbar-padding-y: 8;

$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-brand-color: rgba($black, 0.9);
$navbar-light-brand-hover-color: rgba($black, 0.9);
$navbar-light-color: rgba($black, 0.5);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-toggler-border-color: rgba($black, 0.1);

$navbar-dark-active-color: $white;
$navbar-dark-brand-color: $white;
$navbar-dark-brand-hover-color: $white;
$navbar-dark-color: rgba($white, 0.8);
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-toggler-border-color: rgba($white, 0.1);

// Toasts

$toast-font-size: 14;

$toast-background-color: $white;
$toast-border-color: $gray-400;
$toast-header-background-color: $gray-200;
$toast-header-border-color: $gray-400;
$toast-header-color: $gray-600;
$toast-max-width: 350;
$toast-padding-x: 12;
$toast-padding-y: 4;
