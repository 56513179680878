//------------------------------------------------------------------------------
// RESETS

table {
  border-collapse: collapse;
  max-width: 100%;
  width: 100%;
}

th,
td {
  text-align: inherit;
  vertical-align: top;
}

thead th {
  vertical-align: bottom;
}


//------------------------------------------------------------------------------
// TABLE

.table {
  background-color: transparent;
  margin-bottom: rem(16);

  th,
  td {
    border-top: rem($border-width) solid $gray-500;
    padding: rem(12);
  }

  thead th {
    border-bottom: rem(2) solid $gray-500;
  }

  tfoot {
    display: table-header-group;
  }
}

.table-sm {
  th,
  td {
    padding: rem(6);
  }
}


//------------------------------------------------------------------------------
// TABLE: STRIPING

.table-striped {
  tbody tr:nth-of-type(even) {
    background-color: $gray-100;
  }
}

//------------------------------------------------------------------------------
//Project Table

.selected-project {
  background-color: #CED4DA!important;
}


//------------------------------------------------------------------------------
// TABLE: HOVER

.table-hover {
  tbody tr:hover {
    background-color: $table-hover-bg;
    color: $table-hover-color;
  }
}


//------------------------------------------------------------------------------
// TABLE: BACKGROUND

@mixin table-row-variant($state, $background, $border: null) {
  .table-#{$state} {
    &,
    > th,
    > td {
      background-color: $background;
      color: color-yiq($background);
    }

    @if $border != null {
      th,
      td,
      thead th,
      tbody + tbody {
        border-color: $border;
      }
    }
  }

  .table-hover {
    $hover-background: darken($background, 5%);

    .table-#{$state} {
      &:hover {
        background-color: $hover-background;

        > td,
        > th {
          background-color: $hover-background;
        }
      }
    }
  }
}

@each $color, $value in $colors {
  @include table-row-variant($color, color-level($value, -9), color-level($value, -6));
}


//------------------------------------------------------------------------------
// TABLE: RESPONSIVE

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint);
    $infix: breakpoint-infix($next);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        width: 100%;

        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}
