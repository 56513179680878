//------------------------------------------------------------------------------
// TOASTS

.toast-wrapper {
  bottom: rem($spacer * 2);
  position: absolute;
  right: 0;
  right: rem($grid-min-gutter-width / 2);
  z-index: 2000;

  @media (min-width: rem($break-sm)) {
    right: calc(#{rem($grid-min-gutter-width)} + #{($grid-max-gutter-width - $grid-min-gutter-width) / 16} * (100vw - #{rem($break-sm)}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: rem($break-xl)) {
    right: rem($grid-max-gutter-width);
  }
}

.toast {
  @include font-size($toast-font-size);
  backdrop-filter: blur(rem(10));
  background-clip: padding-box;
  background-color: $toast-background-color;
  border: rem($border-width) solid $toast-border-color;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  max-width: rem($toast-max-width);
  opacity: 0;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: rem($toast-padding-x);
  }

  &.showing {
    opacity: 1;
  }

  &.show {
    display: block;
    opacity: 1;
  }

  &.hide {
    display: none;
  }
}

.toast-header {
  align-items: center;
  background-clip: padding-box;
  background-color: $toast-header-background-color;
  border-bottom: rem($border-width) solid $toast-header-border-color;
  color: $toast-header-color;
  display: flex;
  padding: rem($toast-padding-y) rem($toast-padding-x);
}

.toast-body {
  padding: rem($toast-padding-x);
}
