//------------------------------------------------------------------------------
// BODY

body {
  @include font-size($min-font-size, $max-font-size);
  background-color: $white;
  color: $body-color;
  font-feature-settings: "liga", "kern";
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: $line-height-base;
  margin: 0;
  min-width: $body-min-width;
  overflow-y: scroll;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  text-rendering: geometricPrecision;
  -webkit-text-size-adjust: 100%;
}


//------------------------------------------------------------------------------
// HEADING

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $body-color;
  line-height: 1.2;
  margin-bottom: rem(14);

  small {
    font-size: 75%;
  }
}

h1,
.h1 {
  @include font-size($h1-min-font-size, $h1-max-font-size);
}

h2,
.h2 {
  @include font-size($h2-min-font-size, $h2-max-font-size);
}

h3,
.h3 {
  @include font-size($h3-min-font-size, $h3-max-font-size);
}

h4,
.h4 {
  @include font-size($h4-min-font-size, $h4-max-font-size);
  line-height: $line-height-base;
}

h5,
.h5 {
  @include font-size($h5-min-font-size, $h5-max-font-size);
  line-height: $line-height-base;
}


//------------------------------------------------------------------------------
// PARAGRAPGS

p {
  margin-bottom: rem(14);
  margin-top: 0;
}


//------------------------------------------------------------------------------
// LINKS

a {
  background-color: transparent;
  color: $secondary;
  text-decoration: none;

  &:focus {
    outline: rem(2) solid $secondary;
    outline-offset: rem(1);
    position: relative;
    text-decoration: none;
    z-index: 3000;
  }

  &:hover {
    color: $secondary;
    text-decoration: underline;
  }

  &[data-focus-method="mouse"],
  &[data-focus-method="touch"] {
    outline: 0;
  }
}


//------------------------------------------------------------------------------
// HORIZONTAL RULES

hr {
  border: 0;
  border-top: rem($border-width) solid $gray-400;
  box-sizing: content-box;
  height: 0;
  margin-bottom: rem(16);
  margin-top: rem(16);
  overflow: visible;
}


//------------------------------------------------------------------------------
// EMPHASIS

small,
.small {
  font-size: $small-font-size;
}

abbr[title],
abbr[data-original-title] {
  border-bottom: 0;
  cursor: help;
  text-decoration: none;
}


//------------------------------------------------------------------------------
// LISTS

ol,
ul {
  margin-bottom: rem(16);
  margin-top: 0;

  ol,
  ul {
    margin-bottom: 0;
  }
}

dl {
  margin-bottom: rem(16);
  margin-top: 0;
}

.list-styled {
  list-style: escape-svg(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><rect fill='#{$body-color}' height='7' width='7' x='0' y='1'/></svg>"));
  margin-left: 0;
  padding-left: rem(17);
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.links_list {
  list-style: none;
  a {
    color: #07C;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: none;
    }
  }
}

.link_form_container {
  .link_form_item {
    border: 1px solid rgba(0, 0, 0, 0.22);
    border-radius: 5px;
  }
  .delete_link, .add_link_form i {
    &:hover {
      color: $secondary;
      cursor: pointer;
    }
  }
}
