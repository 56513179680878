//------------------------------------------------------------------------------
// NAV

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-link {
  display: block;
  padding: rem(6) rem(10);

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.disabled {
    color: $gray-600;
    cursor: default;
    pointer-events: none;
  }
}

.nav-pills {
  .nav-link {
    border-radius: rem($border-radius);
  }

  .nav-link.active {
    background-color: $primary;
    color: $white;
  }
}
