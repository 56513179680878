// stylelint-disable declaration-no-important

//------------------------------------------------------------------------------
// NAVBAR

.navbar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: rem($navbar-padding-y) rem($navbar-padding-x);
  position: relative;

  > .container,
  > .container-fluid {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.navbar-brand {
  display: inline-block;
  line-height: inherit;
  margin-right: rem($navbar-padding-x);
  white-space: nowrap;

  &:focus,
  &:hover {
    outline: none;
  }
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  .nav-link {
    padding-left: 0;
    padding-right: 0;
  }

  .dropdown-menu {
    float: none;
    position: static;
  }

  .active {
    position: relative;

    &:after {
      border-bottom: rem(5) solid $secondary;
      bottom: rem(-$navbar-padding-y);
      content: "";
      left: 0;
      position: absolute;
      right: 0;
    }
  }
}


//------------------------------------------------------------------------------
// NAVBAR: TEXT

.navbar-text {
  display: inline-block;
  padding-bottom: rem($nav-link-padding-y);
  padding-top: rem($nav-link-padding-y);
}


//------------------------------------------------------------------------------
// NAVBAR: COLLAPSES

.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}

.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint);
    $infix: breakpoint-infix($next);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        > .container,
        > .container-fluid {
          padding-left: 0;
          padding-right: 0;
        }
      }

      @include media-breakpoint-up($next) {
        flex-flow: row nowrap;
        justify-content: flex-start;

        .navbar-nav {
          flex-direction: row;

          .dropdown-menu {
            position: absolute;
          }

          .nav-link {
            padding-left: rem($navbar-nav-link-padding-x);
            padding-right: rem($navbar-nav-link-padding-x);
          }
        }

        > .container,
        > .container-fluid {
          flex-wrap: nowrap;
        }

        .navbar-collapse {
          display: flex!important;
          flex-basis: auto;
        }

        .navbar-toggler {
          display: none;
        }
      }
    }
  }
}


//------------------------------------------------------------------------------
// NAVBAR: THEMES

.navbar-light {
  .navbar-brand {
    color: $navbar-light-brand-color;

    &:focus,
    &:hover {
      color: $navbar-light-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-light-color;

      &:focus,
      &:hover {
        color: $navbar-light-hover-color;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-light-active-color;
    }
  }

  .navbar-toggler {
    border-color: $navbar-light-toggler-border-color;
    color: $navbar-light-color;
  }

  .navbar-text {
    color: $navbar-light-color;

    a {
      color: $navbar-light-active-color;

      &:focus,
      &:hover {
        color: $navbar-light-active-color;
      }
    }
  }

  .icon {
    fill: $navbar-light-color;
  }
}

.navbar-dark {
  .navbar-brand {
    color: $navbar-dark-brand-color;

    &:focus,
    &:hover {
      color: $navbar-dark-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-dark-color;

      &:focus,
      &:hover {
        color: $navbar-dark-hover-color;
      }

      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-dark-active-color;
    }
  }

  .navbar-toggler {
    border-color: $navbar-dark-toggler-border-color;
    color: $navbar-dark-color;
  }

  .navbar-text {
    color: $navbar-dark-color;

    a {
      color: $navbar-dark-active-color;

      &:focus,
      &:hover {
        color: $navbar-dark-active-color;
      }
    }
  }

  .icon {
    fill: $navbar-dark-color;
  }
}
